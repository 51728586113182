import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <p>
          Welcome to Instant Legal!
        </p>
      <span>A Nicholls and Nicholls brand</span>
    </div>
  );
}

export default App;
